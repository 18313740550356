<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-card elevation="1" width="400" class="mx-auto">
      <v-card-title primary-title class="text-center mb-n4">
        <v-img
          height="150px"
          width="150px"
          class="rounded-circle mx-auto"
          src="@/assets/gratifyLogo.jpeg"
          alt="John"
        ></v-img>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent class="ma-3">
          <v-text-field
            v-model="editedItem.firstname"
            :rules="[rules.firstname]"
            name="firstname"
            label="First Name"
            variant="outlined"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.lastname"
            :rules="[rules.lastname]"
            name="lastname"
            label="Last Name"
            variant="outlined"
            class="mt-2"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.email"
            :rules="[rules.email]"
            name="email"
            label="Email"
            variant="outlined"
            class="mt-2"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.phone"
            :rules="[rules.phonenumber]"
            name="phone"
            label="Phone Number"
            variant="outlined"
            class="mt-2"
          ></v-text-field>

          <v-btn
            :loading="loading"
            block
            color="#FFC501"
            size="large"
            type="submit"
            variant="elevated"
            class="mt-3"
            @click="save"
          >
            <span class="text-white"> Register </span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
  <script>
import AuthService from "@/services/auth.service";

export default {
  data() {
    return {
      show1: false,
      show2: false,
      user: "",
      rules: {
        email: (value) => !!value || "email required",
        firstname: (value) => !!value || "first name required",
        lastname: (value) => !!value || "last name required",
        phonenumber: (value) => !!value || "phone number required",
      },
      loading: false,
      editedItem: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
      },
      defaultItem: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
      },
    };
  },

  computed: {
    userResetEmail() {
      return localStorage.getItem("user_reset_email");
    },
  },

  methods: {
    async validate() {
      this.valid = await this.$refs.form.validate();
    },


    formatPhoneNumber(phoneNumber) {
      const pattern = /^(0|254|\+254)([1-9]\d{8})$/;
      if (pattern.test(phoneNumber)) {
        if (phoneNumber.startsWith("0")) {
          return "+254" + phoneNumber.slice(1);
        } else {
          return "+254" + phoneNumber.slice(-9);
        }
      } else if (/^[71]/.test(phoneNumber)) {
        return "+254" + phoneNumber;
      } else {
        return phoneNumber;
      }
    },

    async save() {
      this.loading = true;
      this.editedItem.phone = this.formatPhoneNumber(this.editedItem.phone);
      AuthService.selfRegister(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.user = response.data;
            this.$store.dispatch("alert/success", response.data.message);
            this.$router.push(this.$route.query.redirect || "/verify");
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error);
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
  },
};
</script>
  