<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="organisations"
      :search="search"
      :custom-filter="filterText"
      class="elevation-2"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar color="gray" flat>
          <v-toolbar-title
            class="text-h6 text-uppercase font-weight-bold text--darken-1 justify-center text-primary"
          >
            Organisations
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="search"
                density="compact"
                variant="outlined"
                label="Search"
                append-inner-icon="mdi-magnify"
                class="ma-3"
                single-line
                hide-details
              ></v-text-field>
              <v-btn
                color="secondary"
                dark
                size="large"
                prepend-icon="mdi-plus-thick"
                variant="elevated"
                v-bind="props"
              >
                New Organisation
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span
                  class="text-h6 font-weight-bold text--darken-1 justify-center text-primary"
                  >{{ formTitle }}</span
                >
              </v-card-title>

              <v-card-text>
                <v-form lazy-validation ref="form">
                  <v-container>
                    <v-row>
                      <v-col class="mt-n3" cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.organisation_name"
                          label="Organisation Name"
                          variant="outlined"
                          density="compact"
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mt-n3" cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.id_parent"
                          variant="outlined"
                          density="compact"
                          label="Select Parent Organisation"
                          clearable
                          :items="allOrgs"
                          item-title="organisation_name"
                          item-value="id"
                        ></v-autocomplete>
                      </v-col>
                      <v-col class="mt-n3" cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.oty_id"
                          variant="outlined"
                          density="compact"
                          label="Select Organisation Types"
                          clearable
                          :items="orgTypes"
                          item-title="organisation_type"
                          item-value="id"
                        ></v-autocomplete>
                      </v-col>
                      <v-col class="mt-n3" cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="selectedRoleType"
                          variant="outlined"
                          density="compact"
                          label="Select Role Type"
                          clearable
                          :items="roleTypes"
                          item-title="description"
                          item-value="id"
                        ></v-autocomplete>
                      </v-col>
                      <v-col class="mt-n3" cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.tax_id"
                          label="Tax Id"
                          variant="outlined"
                          density="compact"
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" variant="elevated" @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="secondary"
                  variant="elevated"
                  @click="save"
                  :loading="loading"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_parent`]="{ item }">
        {{ parentOrg(item) }}
      </template>
      <template v-slot:[`item.oty_id`]="{ item }">
        {{ orgType(item) }}
      </template>
      <template v-slot:[`item.employees`]="{ item }">
        <v-btn class="ma-2" color="secondary" @click="getOrgemps(item.id)"
          ><span class="text-caption justify-center"
            >Employees <v-icon icon="mdi-account-group" end></v-icon> </span
        ></v-btn>
      </template>

      <template v-slot:[`item.average_rating`]="{ item }">
        <div class="text-error" v-if="item.average_rating == 0">
          {{ "No Rating" }}
        </div>
        <div @click="showRating(item)" v-else>
          <v-tooltip
            text="click to view ratings"
            activator="parent"
            location="bottom"
          >
            <template v-slot:activator="{ tooltip }">
              <v-rating
                hover
                v-bind="tooltip"
                readonly
                :length="5"
                :size="21"
                :model-value="item.average_rating"
                active-color="amber"
              />
            </template>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="primary"
          size="small"
          class="me-3"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon color="error" size="small" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data> No data Available </template>
    </v-data-table>

    <!-- Rating Dialog -->
    <v-dialog
      v-model="dialogRating"
      content-class="my-custom-dialog"
      max-width="550"
    >
      <v-card :loading="loading">
        <template v-slot:title>
          <span style="display: flex; justify-content: flex-start">{{
            editedItem.organisation_name + " " + "reviews"
          }}</span>
          <span style="display: flex; justify-content: flex-end">
            <v-icon
              color="primarysecondary"
              class="mt-n8"
              size="30"
              icon="mdi-close"
              @click="dialogRating = false"
            ></v-icon>
          </span>
        </template>
        <v-card-text>
          <v-list lines="two">
            <v-list-item
              v-for="(tip, i) in tips"
              :key="i"
              :subtitle="
                tip.comment ? 'Comment:' + ' ' + tip.comment : 'No comments'
              "
            >
              <template v-slot:prepend>
                <v-avatar color="secondary">
                  <v-icon icon-color="white" icon="mdi-account"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <v-row>
                  <v-col v-if="is_admin" cols="7">
                    {{
                      "Tipped" +
                      " " +
                      getAmountWithCurrencyOrZero(tip.mpesa_results)
                    }}
                  </v-col>
                  <v-col cols="5">
                    <v-rating
                      hover
                      readonly
                      :length="5"
                      :size="14"
                      class="mt-n2 ml-15"
                      :model-value="tip.rating"
                      half-increments
                      variant="compact"
                      active-color="amber"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-list-item>
          </v-list>
          <v-pagination
            v-model="tipsPage"
            :length="tipsTotalPages"
            @click="onPageChange"
            class="mt-4"
            color="primary"
            rounded="circle"
          ></v-pagination>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-3"
            variant="elevated"
            @click="dialogRating = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- here end of rating dialog -->
  </div>
</template>

<script>
import OrganisationService from "@/services/organisation_service";
import OrganisationTypeService from "@/services/organisation_types_service";
import RoleTypeService from "@/services/role_type_service";
import authHeader from "@/services/auth-header";
import axios from "axios";

export default {
  components: {},
  data: () => ({
    is_admin: false,
    dialog: false,
    dialogDelete: false,
    dialogTips: false,
    dialogQrcode: false,
    valid: false,
    roleTypes: [],
    orgRating: {},
    search: "",
    tips: [],
    orgTypes: [],
    tipsPage: 1,
    employees: {},
    allOrgs: {},
    tipsTotalPages: 1,
    dialogRating: false,
    defaultRoleType: 1,
    organisationTips: [],
    organisations: [],
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "At least one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
    },
    headers: [
      {
        title: "#",
        align: "start",
        sortable: true,
        value: "id",
      },
      { title: "Organisation Name", value: "organisation_name" },
      { title: "Parent Organisation", value: "parent_organisation.organisation_name" },
      { title: "Organisation Type", value: "oty_id", filterable: true },
      { title: "Rating", value: "average_rating" },
      { title: "Employees", value: "employees", align: "start" },
      {
        title: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],

    loading: true,
    editedIndex: -1,
    editedItem: {
      organisation_name: "",
      id_parent: "",
      oty_id: "",
      tax_id: "",
      role_type: "",
      parent: "",
    },
    defaultItem: {
      organisation_name: "",
      id_parent: "",
      oty_id: "",
      tax_id: "",
      role_type: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Organisation" : "Edit Organisation";
    },
    user() {
      return this.$store.state.auth.user;
    },

    selectedRoleType: {
      get() {
        return (
          this.roleTypes.find(
            (role) => role.description.toLowerCase() === "employer"
          ) || null
        );
      },

      set(value) {
        this.editedItem.role_type = value ? value.id : null;
      },
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getOrganisations();
    this.getAllOrganisations();
    this.getOrgTypes();
    this.getRoleTypes();
    this.checkRole();
  },

  methods: {
    async validate() {
      this.valid = await this.$refs.form.validate();
    },

    checkRole() {
      localStorage.getItem("role_category") == 'administrator'
        ? (this.is_admin = true)
        : (this.is_admin = false);
    },
    // search
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toLowerCase().includes(search.toLowerCase())
      );
    },

    getRoleTypes() {
      return RoleTypeService.index().then(
        (response) => {
          if (response.status == 200) {
            this.roleTypes = response.data.data;

            this.loading = false;
          } else {
            this.roleTypes = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.roleTypes = [];
          if (error.response.status == 401) {
            this.handleLogout();
          } else {
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        }
      );
    },

    getOrganisations() {
      OrganisationService.index().then(
        (response) => {
          if (response.status == 200) {
            this.organisations = response.data.data;
            this.loading = false;
            const ratingPromises = this.organisations.map((organisation) => {
              return this.getOrgRating(organisation.id).then((rating) => {
                organisation.average_rating = rating ? rating.averageRating : 0;
                organisation.rating_count = rating ? rating.ratingCount : 0;
                return organisation;
              });
            });

            Promise.all(ratingPromises).then((updatedOrganisations) => {
              this.organisations = updatedOrganisations;
            });
          } else {
            this.organisations = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.organisations = [];
          this.loading = false;
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
          if (error.response && error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    getAllOrganisations() {
      OrganisationService.indexAll().then(
        (response) => {
          if (response.status == 200) {
            this.allOrgs = response.data.data;
            this.loading = false;
          } else {
            this.allOrgs = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.allOrgs = [];
          this.loading = false;
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
          if (error.response && error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    getOrgRating(id) {
      return OrganisationService.getOrgRating(id).then(
        (response) => {
          if (response.status == 200) {
            // console.log('here', response.data.data);
            //
            return response.data.data;
          } else {
            // this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
            return null;
          }
        },
        (error) => {
          if (error.response && error.response.status == 401) {
            this.handleLogout();
          } else {
            console.log(error);
            // this.$store.dispatch("alert/error", error.message);
            return null; // Return null in case of error
          }
        }
      );
    },

    getAmountWithCurrencyOrZero(mpesaResults) {
      const amount = mpesaResults !== null ? mpesaResults.amount : 0.0;
      const formattedAmount = amount.toFixed(2);
      return `KES ${formattedAmount}`;
    },

    getFullNameOrNickname(person) {
      const firstName = person.firstname || "";
      const lastName = person.lastname || "";
      const nickname = person.nickname || "tipper";
      if (firstName.trim() !== "" && lastName.trim() !== "") {
        return `${firstName} ${lastName}`;
      } else {
        return nickname;
      }
    },

    getOrgTypes() {
      return OrganisationTypeService.index().then(
        (response) => {
          if (response.status == 200) {
            this.orgTypes = response.data.data;

            this.loading = false;
          } else {
            this.roleCategoryType = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.roleCategoryType = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    parentOrg(item) {
      const parentOrganization = this.organisations.find(
        (org) => org.id === item.id_parent
      );
      return parentOrganization ? parentOrganization.organisation_name : "N/A";
    },

    orgType(item) {
      const organisationType = this.orgTypes.find(
        (orgTyp) => orgTyp.id === item.oty_id
      );
      return organisationType ? organisationType.organisation_type : "N/A";
    },

    // Data Manipulation
    deleteItem(item) {
      this.loading = true;
      this.editedIndex = this.organisations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.organisations.splice(this.editedIndex, 1);
      OrganisationService.delete(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.loading = false;
            this.close();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
          this.loading = false;
        }
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.loading = false;
      });
    },

    getOrgemps(orgId) {
      setTimeout(() => {
        localStorage.setItem("orgId", orgId);
        this.$router.push("/employees");
      }, 1000);
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    editItem(item) {
      this.editedIndex = this.organisations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    showQrcode(item) {
      this.editedIndex = this.organisations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogQrcode = true;
      //
    },

    async showRating(item) {
      this.loading = false;
      this.editedItem.organisation_name = item.organisation_name;
      await this.getOrganisationTips(item);
      this.editedIndex = this.organisations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRating = true;
    },

    showTips(item) {
      this.loading = false;
      this.editedIndex = this.organisations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.tips = item.person.tips.data;
      this.tipsPage = item.person.tips.current_page;
      this.tipsTotalPages = item.person.tips.last_page;
      this.path = item.person.tips.path;
      this.dialogTips = true;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      await this.validate();
      if (this.valid || this.valid.valid) {
        this.editedItem.role_type = this.selectedRoleType.id;
        this.loading = true;
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.organisations[this.editedIndex], this.editedItem);
          OrganisationService.update(this.editedItem).then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.getOrganisations();
              this.close();
            } else {
              this.$store.dispatch(
                "alert/error",
                "Invalid data, please check the form try again!"
              );
              this.loading = false;
            }
            (error) => {
              this.getOrganisations();
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              if (error.response.status == 401) {
                this.handleLogout();
              }
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
            };
          });
        } else {
          // Create
          OrganisationService.store(this.editedItem).then(
            (response) => {
              if (response.status == 201) {
                this.organisations.push(this.editedItem);
                this.$store.dispatch("alert/success", "Added Successfully");
                this.getOrganisations();
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", "Something Went Wrong!");
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error);
              }
              if (error.response.status == 401) {
                this.handleLogout();
              }
              this.close();
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },

    getOrganisationTips(item) {
      return OrganisationService.getAllOrgTips(item.id, this.tipsPage)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.organisationTips = response.data.data;
            this.tips = this.organisationTips.tips.data;
            console.log("tipsss", this.organisationTips);

            this.tipsPage = this.organisationTips.tips.current_page;
            this.tipsTotalPages = this.organisationTips.tips.last_page;
            this.path = this.organisationTips.tips.path;

            this.loading = false;
          } else {
            this.tips = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        })
        .catch((error) => {
          this.tips = [];
          if (error.response && error.response.status === 401) {
            this.handleLogout();
          }
          console.error(error);
          this.$store.dispatch("alert/error", error.message);
        });
    },

    onPageChange() {
      const url = `${this.path}?page=${this.tipsPage}`;
      axios
        .get(url, { headers: authHeader() })
        .then((response) => {
          this.organisationTips = response.data.data.tips.data;
          this.totalPages = response.data.data.tips.last_page;
          this.tips = this.organisationTips;
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch("alert/error", error.message);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        });
    },

    convertToFloat(value) {
      return isNaN((value = Number(value))) ? NaN : value.toFixed(1);
    },

    // getOrganisationTips(item) {
    //   return OrganisationService.getOrgTips(item.id, this.tipsPage)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.loading = false;
    //         this.organisationTips = response.data.data;
    //         if (this.organisationTips && this.organisationTips.tips) {
    //           this.employees = this.organisationTips.tips.map((employee) => {
    //             this.tips = employee.person.tips.data;
    //             this.tipsPage = employee.person.tips.current_page;
    //             this.tipsTotalPages = employee.person.tips.last_page;
    //             this.path = employee.person.tips.path;
    //             this.tips = employee.person.tips.data;
    //             return employee;
    //           });
    //         } else {
    //           this.$store.dispatch("alert/error", "No tips forund");
    //         }
    //
    //         this.loading = false;
    //       } else {
    //         this.organisationTips = [];
    //         this.loading = false;
    //         this.$store.dispatch("alert/error", response.data.console.error);
    //         console.log(response.data.console.error);
    //       }
    //     })
    //     .catch((error) => {
    //       this.organisationTips = [];
    //       if (error.response && error.response.status === 401) {
    //         this.handleLogout();
    //       }
    //       console.error(error);
    //       this.$store.dispatch("alert/error", error.message);
    //     });
    // },

    // onPageChange(item) {
    //   console.log(item);
    //   const url = `${this.path}?page=${this.tipsPage}`;
    //   axios
    //     .get(url, { headers: authHeader() })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.loading = false;
    //         this.organisationTips = response.data.data;

    //         if (this.organisationTips && this.organisationTips.tips) {
    //           this.employees = this.organisationTips.tips.map((employee) => {
    //             this.tips = employee.person.tips.data;
    //             console.log("employee", employee.person.tips.data);
    //             this.tipsPage = employee.person.tips.current_page;
    //             this.tipsTotalPages = employee.person.tips.last_page;
    //             this.path = employee.person.tips.path;
    //             return employee;
    //           });
    //         }
    //         console.log("the tips", this.tips);
    //
    //         this.loading = false;
    //       } else {
    //         this.organisationTips = [];
    //         this.loading = false;
    //         this.$store.dispatch("alert/error", response.data.console.error);
    //         console.log(response.data.console.error);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       this.$store.dispatch("alert/error", error.message);
    //       if (error.response.status == 401) {
    //         this.handleLogout();
    //       }
    //     });
    // },
  },
};
</script>