<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-card elevation="1" width="400" class="mx-auto">
      <v-card-title primary-title class="text-center">
        <v-img
          height="150px"
          width="150px"
          class="rounded-circle mx-auto"
          src="@/assets/gratifyLogo.jpeg"
          alt="John"
        ></v-img>
      </v-card-title>
      <v-card-text>
        <v-form class="ma-3">
          <div class="ma-auto position-relative" style="max-width: 300px">
            <v-otp-input v-model="editedItem.otp" @finish="save"></v-otp-input>
          </div>
          <v-btn
            :loading="loading"
            block
            color="#FFC501"
            size="large"
            variant="elevated"
            class="mt-7"
            @click="save"
          >
            Submit
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import AuthService from "@/services/auth.service";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      otp: "",
      loading: false,
      editedItem: {
        otp: "",
      },
    };
  },

  methods: {
    save() {
      this.loading = true;
      // console.log("itemmsss", this.editedItem);
      AuthService.verifyOtp(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.otp = response.data.data;
            localStorage.setItem("otp", this.editedItem.otp)
            this.$router.push(this.$route.query.redirect || "/reset-password");
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.data.message);
			this.$router.push(this.$route.query.redirect || "/forgotten-password");
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error);
            this.$router.push(this.$route.query.redirect || "/forgotten-password");
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.dispatch("alert/error", error.response.data.message);
          this.$router.push(this.$route.query.redirect || "/forgotten-password");
        }
      );
    },
  },
};
</script>
