<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="users"
			:search="search"
			:custom-filter="filterText"
			class="elevation-2"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar color="gray" flat>
					<v-toolbar-title
						class="text-h6 text-uppercase font-weight-bold text--darken-1 justify-center text-primary"
					>
						Approve Users
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" max-width="500px">
						<template v-slot:activator="{ props }">
							<v-text-field
								v-model="search"
								density="compact"
								variant="outlined"
								label="Search"
								append-inner-icon="mdi-magnify"
								class="ma-3"
								single-line
								hide-details
							></v-text-field>
							<v-btn
								color="secondary"
								dark
								size="large"
								prepend-icon="mdi-plus-thick"
								variant="elevated"
								disabled
								v-bind="props"
							>
								New User
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span
									class="text-h6 font-weight-bold text--darken-1 justify-center text-primary"
									>{{ formTitle }}</span
								>
							</v-card-title>

							<v-card-text>
								<v-form lazy-validation ref="form">
									<v-container>
										<v-row>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="
														editedItem.firstname
													"
													label="First Name"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>
																						<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="
														editedItem.lastname
													"
													label="Last Name"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>
																						<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="
														editedItem.email
													"
													label="Email"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="
														editedItem.phoneNumber
													"
													label="Phone Number"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>

											<!-- approved -->
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-autocomplete
													v-model="
														editedItem.status
													"
													variant="outlined"
													density="compact"
													label="Approve account"
													clearable
													:items="['Pending', 'Approved']"
													:item-title=" 0 ? 'Pending' : 'Approved'"
												></v-autocomplete>
											</v-col>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
											<v-textarea 
												clearable 
												label="Description"
												v-model="editedItem.description"
												variant="outlined"
												density="compact"
											>

											</v-textarea>
											</v-col>
										</v-row>
									</v-container>
								</v-form>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="error"
									variant="elevated"
									@click="close"
								>
									Cancel
								</v-btn>
								<v-btn
									color="secondary"
									variant="elevated"
									@click="save"
									:loading="loading"
								>
									Save
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5"
								>Are you sure you want to delete this
								user?</v-card-title
							>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="blue-darken-1"
									variant="text"
									@click="closeDelete"
									>Cancel</v-btn
								>
								<v-btn
									color="blue-darken-1"
									variant="text"
									@click="deleteItemConfirm"
									>OK</v-btn
								>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>

			<template v-slot:[`item.approved`]="{ item }">
				{{ item.approved == 1 ? "Approved" : "Pending" }}
			</template>
			<!-- <template v-slot:[`item.created_at`]="{ item }">
				{{ formatDate(item.created_at) }}
			</template> -->
			<!-- <template v-slot:[`item.updated_at`]="{ item }">
				{{ formatDate(item.updated_at) }}
			</template> -->

			<template v-slot:[`item.actions`]="{ item }">
				<v-icon
					color="primary"
					size="small"
					class="me-3"
					@click="editItem(item)"
				>
					mdi-pencil
				</v-icon>
				<!-- <v-icon color="error" size="small" @click="deleteItem(item)">
					mdi-delete
				</v-icon> -->
			</template>
			<template v-slot:no-data> No data Available </template>
		</v-data-table>
	</div>
</template>

<script>

import UserService from "@/services/user.service";

export default {
	components: {
		
	},
	data: () => ({
		dialog: false,
		dialogDelete: false,
		dialogQrcode: false,
		valid: false,
		search: "",
		users: [],
		rules: {
			select: [(v) => !!v || "An item should be selected"],
			select2: [
				(v) => v.length > 0 || "At least one item should be selected",
			],
			required: [
				(v) => !!v || "Field is required",
				(v) => (v && v.length >= 3) || "Min 3 characters",
			],
		},
		headers: [
			{
				title: "#",
				align: "start",
				sortable: true,
				value: "id",
			},
			{ title: "Name", value: "name" },
			{ title: "Email", value: "email" },
			{ title: "Status", value: "approved" },
			// { title: "Created at", value: "created_at" },
			// { title: "Updated at", value: "updated_at" },
			{
				title: "Actions",
				value: "actions",
				sortable: false,
				align: "center",
			},
		],

		loading: true,
		editedIndex: -1,
		editedItem: {
			name: "",
			firstname: "",
			lastname: "",
			email: "",
			phoneNumber: "",
			status: "",
			approved: "",
			description: "",
		},
		defaultItem: {
			name: "",
			firstname: "",
			lastname: "",
			email: "",
			phoneNumber: "",
			status: "",
			approved: "",
			description: "",
		},
	}),

	computed: {
		formTitle() {
			return this.editedIndex === -1
				? "New User"
				: "Edit User";
		},
		user() {
			return this.$store.state.auth.user;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},

		dialogDelete(val) {
			val || this.closeDelete();
		},
	},

	created() {
		this.getUsers();
	},

	methods: {
		async validate() {
			this.valid = await this.$refs.form.validate();
		},

		// search
		filterText(value, search) {
			return (
				value != null &&
				search != null &&
				typeof value === "string" &&
				value.toLowerCase().includes(search.toLowerCase())
			);
		},

		formatDate(isoDate) {
			const date = new Date(isoDate);
			const dd = String(date.getDate()).padStart(2, "0");
			const mm = String(date.getMonth() + 1).padStart(2, "0");
			const yyyy = date.getFullYear();
			const formattedDate = `${dd}-${mm}-${yyyy}`;
			return formattedDate;
		},

		getUsers() {
			return UserService.getAllUsers().then(
				(response) => {
					if (response.status == 200) {
						this.users = response.data.data;

						this.loading = false;
					} else {
						this.roleCategoryType = [];
						this.loading = false;
						this.$store.dispatch(
							"alert/error",
							response.data.console.error
						);
						console.log(response.data.console.error);
					}
				},
				(error) => {
					this.roleCategoryType = [];
					if (error.response.status == 401) {
						this.handleLogout();
					} else {
					console.log(error);
					this.$store.dispatch("alert/error", error.message);
					}
				}
			);
		},

		// Data Manipulation
		deleteItem(item) {
			this.loading = true;
			this.editedIndex = this.users.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.users.splice(this.editedIndex, 1);
			UserService.deleteUser(this.editedItem).then(
				(response) => {
					if (response.status == 200) {

						this.loading = false;
						this.close();
					} else {
						this.$store.dispatch(
							"alert/error",
							response.data.message
						);
					}
				},
				(error) => {
					if (error.response.status == 422) {
						this.$store.dispatch(
							"alert/error",
							error.response.data.message
						);
						this.loading = false;
					}
					if (error.response.status == 401) {
						this.handleLogout();
					}
					console.log(error);
					this.$store.dispatch(
						"alert/error",
						error.response.data.message
					);
					this.loading = false;
				}
			);
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				this.loading = false;
			});
		},
		handleLogout() {
			this.$store.commit('logout');
			this.$store.dispatch('resetInactivityTimeout');
			this.$store.dispatch("auth/logout");
			this.$store.dispatch('resetInactivityTimeout');
			this.$router.push("/login");
			this.$store.dispatch("alert/success", 'Logout Successfully');
		},

		editItem(item) {
			this.editedIndex = this.users.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.editedItem.firstname = item.person.firstname;
			this.editedItem.lastname = item.person.lastname;
			this.editedItem.phoneNumber = item.person.phoneNumbers.length > 0 ?  item.person.phoneNumbers[0].number : null;
			this.editedItem.status = this.editedItem.approved === 1 ? 'Approved' : 'Pending';
			this.dialog = true;
		},

		showQrcode(item) {
			this.editedIndex = this.users.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogQrcode = true;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		async save() {
			await this.validate();
			if (this.valid || this.valid.valid) {
				this.loading = true;
				this.editedItem.name = this.editedItem.firstname + ' ' + this.editedItem.lastname;
				this.editedItem.approved = this.editedItem.status === 'Pending' ? 0 : 1;
				// console.log('the itemss', this.editedItem);
				// edit
				if (this.editedIndex > -1) {
					UserService.updateUser(this.editedItem).then((response) => {
						if (response.status == 201) {
							this.$store.dispatch(
								"alert/success",
								response.data.message
							);
							this.loading = false;
							this.getUsers();
							this.close();
						} else {
							if (response.status == 422){
								this.handleLogout();
							}
							this.$store.dispatch(
								"alert/error",
								"Invalid data, please check the form try again!"
							);
							this.loading = false;
						}
						(error) => {
							this.getUsers();
							if (error.response.status === 422) {
								this.$store.dispatch(
									"alert/error",
									error.response.data.message
								);
							}
							if (error.response.status == 401) {
								this.handleLogout();
							}
							this.$store.dispatch(
								"alert/error",
								error.response.data.message
							);
							this.loading = false;
						};
					});
				} else {
					// Create
					UserService.storeUser(this.editedItem).then(
						(response) => {
							if (response.status == 201) {
								this.users.push(this.editedItem);
								this.$store.dispatch(
									"alert/success",
									"Added Successfully"
								);
								this.getUsers();
								this.loading = false;
								this.close();
							} else {
								this.$store.dispatch(
									"alert/error",
									"Something Went Wrong!"
								);
								this.loading = false;
							}
						},
						(error) => {
							this.loading = false;
							if (error.response.status == 422) {
								this.$store.dispatch("alert/error", error);
								this.loading = false;
							}
							if (error.response.status == 401) {
								this.handleLogout();
							}
							this.close();
							this.$store.dispatch(
								"alert/error",
								error.response.data.message
							);
						}
					);
				}
			}
		},
	},
};
</script>