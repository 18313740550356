<template>
  <v-row class="mr-n5">
    <v-col class="mx-1" cols="12" md="12">
      <v-row>
        <v-col cols="12" md="12" xs="12">
          <!-- Administrator View -->
          <!-- second grid -->
          <v-row v-if="is_admin" class="mt-n1">
            <v-col cols="12" md="5">
              <v-card :loading="loading" color="white" class="rounded-lg">
                <v-row class="">
                  <v-col cols="12" xm="2">
                    <div>
                      <v-card-title
                        class="text-primary text-h6 text-md-h6 text-xs-h6"
                      >
                        Tips
                      </v-card-title>
                      <v-card-subtitle class="text-orange">
                        <v-row class="my-auto">
                          <v-col
                            v-for="(item, i) in statistics"
                            :key="i"
                            cols="3"
                            :class="item.margin"
                            sm="3"
                          >
                            <div class="d-flex align-center">
                              <div class="ma-3">
                                <v-avatar
                                  :color="item.color"
                                  rounded
                                  size="42"
                                  class="elevation-1"
                                >
                                  <v-icon size="24" :icon="item.icon" />
                                </v-avatar>
                              </div>

                              <div class="d-flex flex-column">
                                <span
                                  class="text-caption font-weight-bold text-secondary"
                                >
                                  {{ item.title }}
                                </span>
                                <span
                                  class="text-h6 font-weight-medium text-secondary"
                                  >{{ "KES" + " " + item.amount }}</span
                                >
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-subtitle>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="3">
              <v-card
                :loading="loading"
                color="white"
                class="rounded-lg d-flex"
              >
                <div>
                  <v-card-title
                    class="text-primary text-h6 text-md-h6 text-xs-h6"
                  >
                    Balance
                  </v-card-title>
                  <v-card-subtitle class="text-orange">
                    <v-row class="my-auto">
                      <v-col
                        v-model="balance"
                        :key="balance.title"
                        cols="3"
                        sm="3"
                      >
                        <div class="d-flex align-center">
                          <div class="ma-3">
                            <v-avatar
                              :color="balance.color"
                              rounded
                              size="42"
                              class="elevation-1"
                            >
                              <v-icon size="24" :icon="balance.icon" />
                            </v-avatar>
                          </div>

                          <div class="d-flex flex-column">
                            <span
                              class="text-caption font-weight-bold text-secondary"
                            >
                              {{ balance.title }}
                            </span>
                            <span
                              class="text-h6 text-secondary font-weight-medium"
                              >{{ "KES" + " " + wallet.total_balance }}</span
                            >
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                  <v-card-subtitle class="text-orange">
                    <v-row class="my-auto">
                      <v-col
                        v-model="commission"
                        :key="commission.title"
                        cols="3"
                        sm="3"
                      >
                        <div class="d-flex align-center">
                          <div class="ma-3">
                            <v-avatar
                              :color="commission.color"
                              rounded
                              size="42"
                              class="elevation-1"
                            >
                              <v-icon size="24" :icon="commission.icon" />
                            </v-avatar>
                          </div>

                          <div class="d-flex flex-column">
                            <span
                              class="text-caption font-weight-bold text-secondary"
                            >
                              {{ commission.title }}
                            </span>
                            <span
                              class="text-h6 text-secondary font-weight-medium"
                              >{{ "KES" + " " + commission.balance }}</span
                            >
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" class="">
              <v-card :loading="loading" class="rounded-lg" color="">
                <v-card-title
                  class="text-primary text-h6 text-md-h6 text-xs-h6 ml-4"
                  >Organisations</v-card-title
                >
                <v-card-text
                  class="ml-4 mt-n2 mb-n2 font-weight-bold text-secondary"
                >
                  {{ getCount(organisations) }}
                </v-card-text>
              </v-card>
              <v-card
                :loading="loading"
                class="rounded-lg mt-1"
                color=""
                height=""
              >
                <v-card-title
                  class="text-primary text-h6 text-md-h6 text-xs-h6 ml-4"
                  >Users</v-card-title
                >
                <v-card-text
                  class="ml-4 mt-n2 mb-n2 font-weight-bold text-secondary"
                >
                  {{ getCount(users) }}
                </v-card-text>
              </v-card>

              <!-- 
              <v-card :loading="loading" class="rounded-lg" color="" height="">
                <v-card-title
                  class="text-primary text-h6 text-md-h6 text-xs-h6 ml-4 mt-3"
                  >Employees</v-card-title
                >
                <v-card-text class="ml-4 mt-n2">
                  {{ getCount(employees) }}
                </v-card-text>
              </v-card> -->
            </v-col>
          </v-row>
          <!-- End of Administrator View -->

          <!-- Normal User View-->
          <v-row v-else class="mt-n1">
            <v-col cols="12" md="5">
              <v-card :loading="loading" color="white" class="rounded-lg">
                <v-row class="">
                  <v-col cols="12" xm="2">
                    <div>
                      <v-card-title
                        class="text-primary text-h6 text-md-h6 text-xs-h6"
                      >
                        Ratings
                      </v-card-title>
                      <v-card-subtitle class="text-orange">
                        <v-row class="my-auto">
                          <v-col cols="3" class="mr-8" sm="3">
                            <div class="d-flex align-center">
                              <div class="ma-3">
                                <v-avatar
                                  color="color"
                                  rounded
                                  size="42"
                                  class="elevation-1"
                                >
                                  <v-icon size="24" icon="mdi-account-group" />
                                </v-avatar>
                              </div>

                              <div class="d-flex flex-column">
                                <span
                                  class="text-caption font-weight-bold text-secondary"
                                >
                                  Total Ratings
                                </span>
                                <span
                                  class="text-h6 font-weight-medium text-secondary"
                                  >{{ ratingCount }}</span
                                >
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="3" class="mr-8" sm="3">
                            <div class="d-flex align-center">
                              <div class="ma-3">
                                <v-avatar
                                  color="color"
                                  rounded
                                  size="42"
                                  class="elevation-1"
                                >
                                  <v-icon size="24" icon="mdi-account-group" />
                                </v-avatar>
                              </div>

                              <div class="d-flex flex-column">
                                <span
                                  class="text-caption font-weight-bold text-secondary"
                                >
                                  Employees with Ratings
                                </span>
                                <span
                                  class="text-h6 font-weight-medium text-secondary"
                                  >{{ employeeCount }}</span
                                >
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="3" class="mr-8" sm="3">
                            <div class="d-flex align-center">
                              <div class="ma-3">
                                <v-avatar
                                  color="color"
                                  rounded
                                  size="42"
                                  class="elevation-1"
                                >
                                  <v-icon size="24" icon="mdi-account-group" />
                                </v-avatar>
                              </div>

                              <div class="d-flex flex-column">
                                <span
                                  class="text-caption font-weight-bold text-secondary"
                                >
                                  Average Ratings
                                </span>
                                <span
                                  class="text-h6 font-weight-medium text-secondary"
                                  >{{ averageRating }}</span
                                >
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-subtitle>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="3" class="">
              <v-card :loading="loading" class="rounded-lg" color="">
                <v-card-title
                  class="text-primary text-h6 text-md-h6 text-xs-h6 ml-4"
                  >Organisations</v-card-title
                >
                <v-card-text
                  class="ml-4 mt-n2 mb-n2 font-weight-bold text-secondary"
                >
                  {{ getCount(organisations) }}
                </v-card-text>
              </v-card>
              <v-card
                :loading="loading"
                class="rounded-lg mt-1"
                color=""
                height=""
              >
                <v-card-title
                  class="text-primary text-h6 text-md-h6 text-xs-h6 ml-4"
                  >Users</v-card-title
                >
                <v-card-text
                  class="ml-4 mt-n2 mb-n2 font-weight-bold text-secondary"
                >
                  {{ getCount(users) }}
                </v-card-text>
              </v-card>

              <!-- 
              <v-card :loading="loading" class="rounded-lg" color="" height="">
                <v-card-title
                  class="text-primary text-h6 text-md-h6 text-xs-h6 ml-4 mt-3"
                  >Employees</v-card-title
                >
                <v-card-text class="ml-4 mt-n2">
                  {{ getCount(employees) }}
                </v-card-text>
              </v-card> -->
            </v-col>
          </v-row>
          <!-- End of normal User View-->

          <!-- ASIDE -->
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// import VueApexCharts from "vue3-apexcharts";
import UserService from "@/services/user.service";
import EmployeeService from "@/services/employees.service";
import OrganisationService from "@/services/organisation_service";

export default {
  components: {
    // apexchart: VueApexCharts,
  },
  data: () => ({
    is_admin: false,
    ratingCount: 0,
    averageRating: 0,
    employeeCount: 0,
    interval: {},
    value: 0,
    infocard: true,
    loading: true,
    users: {},
    organisations: {},
    employees: {},
    wallet: {},
    tips: {},
    monthCount: {},

    statistics: [
      {
        title: "Total Tips",
        amount: "",
        icon: "mdi-trending-up",
        color: "primary",
        margin: "mr-8",
      },
      {
        title: "Total Withdrawals",
        amount: "",
        icon: "mdi-cellphone-link",
        color: "primary",
        margin: "ml-15",
      },
    ],

    series: [
      {
        name: "Tips",
        data: [],
        // data: [3, 6, 9, 12, 15, 18, 15, 12, 9, 6, 3],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "12px",
                fontWeight: 900,
              },
            },
          },
        },
      },

      title: {
        text: "",
      },

      xaxis: {
        categories: [],
        // categories: [
        // 	"Jan",
        // 	"Feb",
        // 	"Apr",
        // 	"May",
        // 	"Jun",
        // 	"Jul",
        // 	"Aug",
        // 	"Sep",
        // 	"Oct",
        // 	"Nov",
        // 	"Dec",
        // ],
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ["#847A69"],
      },
    },

    totals: {
      title: "Total Messages",
      color: "error",
      icon: "mdi-poll",
      stats: "$25.6k",
      change: 42,
      subtitle: "Yearly Messaging",
    },

    balance: {
      title: "Total Balance",
      stats: 0,
      icon: "mdi-trending-up",
      color: "primary",
      margin: "mr-3",
    },
    commission: {
      title: "Total Commission",
      stats: "$15.6k",
      icon: "mdi-currency-usd",
      color: "success",
      margin: "ml-3",
      balance: 0.0,
    },
  }),

  beforeUnmount() {
    clearInterval(this.interval);
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
    // this.orgTipsCount();
  },
  created() {
    this.getUsers();
    this.getEmployees();
    this.getOrganisations();
    this.getOrgWallet();
    this.getOrgCommission();
    this.getOrgTips();
    this.orgTipsCount();
    this.checkRole();
    // this.getOrgRatings();
  },

  methods: {
    checkRole() {
      localStorage.getItem("role_category") == "administrator"
        ? (this.is_admin = true)
        : (this.is_admin = false);
    },
    getOrgRatings() {
      return OrganisationService.orgRatingsCount().then(
        (response) => {
          if (response.status == 200) {
            console.log(response.data);
            this.ratingCount = response.data.data.ratingCount;
            this.averageRating = response.data.data.averageRating;
            this.employeeCount = response.data.data.employeeCount;
            this.loading = false;
          } else {
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
          if (error.response.status == 401) {
            this.handleLogout();
            this.$store.dispatch(
              "alert/error",
              "Authorisation expired, please login"
            );
          }
        }
      );
    },
    getUsers() {
      return UserService.getAllUsers().then(
        (response) => {
          if (response.status == 200) {
            this.users = response.data.data;
            this.loading = false;
          } else {
            this.users = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.loading = false;
          this.users = [];
          if (error.response.status == 401) {
            this.handleLogout();
            this.$store.dispatch(
              "alert/error",
              "Authorisation expired, please login"
            );
          }
          console.log(error);
        }
      );
    },

    getEmployees() {
      return EmployeeService.index(1).then(
        (response) => {
          if (response.status == 200) {
            this.employees = response.data.data;
            this.loading = false;
          } else {
            this.employees = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.loading = false;
          this.employees = [];
          if (error.response.status == 401) {
            this.handleLogout();
            this.$store.dispatch(
              "alert/error",
              "Authorisation expired, please login"
            );
          }
          console.log(error);
        }
      );
    },

    getOrganisations() {
      return OrganisationService.index().then(
        (response) => {
          if (response.status == 200) {
            this.organisations = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.loading = false;
          this.organisations = [];
          if (error.response.status == 401) {
            this.handleLogout();
            this.$store.dispatch(
              "alert/error",
              "Authorisation expired, please login"
            );
          }
          console.log(error);
        }
      );
    },

    orgTipsCount() {
      return OrganisationService.orgTipsCount().then(
        (response) => {
          if (response.status == 200) {
            this.monthCount = response.data.data;
            this.updateChartData();
            this.loading = false;
          } else {
            this.monthCount = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.loading = false;
          this.monthCount = [];
          if (error.response.status == 401) {
            this.handleLogout();
            this.$store.dispatch(
              "alert/error",
              "Authorisation expired, please login"
            );
          }
          console.log(error);
        }
      );
    },

    updateChartData() {
      if (Array.isArray(this.monthCount)) {
        this.monthCount.map((item) =>
          this.chartOptions.xaxis.categories.push(item.month)
        );
        this.series[0].data = this.monthCount.map((item) => item.count);
      } else {
        console.error("this.monthCount is not an array.");
      }
    },

    getOrgTips() {
      return OrganisationService.getSortedOrTips(1).then(
        (response) => {
          if (response.status == 200) {
            this.tips = response.data.data;
            this.loading = false;
          } else {
            this.tips = [];
            this.loading = false;
            // this.$store.dispatch("alert/success", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.loading = false;
          this.tips = [];
          if (error.response.status == 401) {
            this.handleLogout();
            this.$store.dispatch(
              "alert/error",
              "Authorisation expired, please login"
            );
          }
          console.log(error);
        }
      );
    },

    getOrgWallet() {
      return OrganisationService.orgWallet().then(
        (response) => {
          if (response.status == 200) {
            this.wallet = response.data.data;
            this.updateStatistics(0, this.wallet.total_tips);
            this.updateStatistics(1, this.wallet.total_withdrawal);
            this.loading = false;
          } else {
            this.wallet = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.loading = false;
          this.wallet = [];
          if (error.response.status == 401) {
            this.handleLogout();
            this.$store.dispatch(
              "alert/error",
              "Authorisation expired, please login"
            );
          }
          console.log(error);
        }
      );
    },

    getOrgCommission() {
      return OrganisationService.orgCommission().then(
        (response) => {
          if (response.status == 200) {
            this.commission.balance = response.data.data.total_commission;
          } else {
            this.commission.balance = 0.00
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.loading = false;
          this.wallet = [];
          if (error.response.status == 401) {
            this.handleLogout();
            this.$store.dispatch(
              "alert/error",
              "Authorisation expired, please login"
            );
          }
          console.log(error);
        }
      );
    },

    // logout
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
      this.$store.dispatch(
        "alert/error",
        "Authorisation expired, please login"
      );
    },

    updateStatistics(index, value) {
      this.statistics[index].amount = value;
    },

    // get count
    getCount(arr) {
      return arr.length;
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
