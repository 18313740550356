// Data sevice
import axios from 'axios'
import authHeader from './auth-header'
import fileHeader from './file-header';
const API_URL = process.env.VUE_APP_API_URL;

class ReportService {

    generateOrgReport(resource) {
        return axios.post(API_URL + 'org-report/' + resource.id, resource, { headers: authHeader() })
    }

    downloadOrgReport(resource) {
        return axios.post(API_URL + 'generate-report/' + resource.id, resource, { headers: fileHeader(), responseType: 'blob' })
    }

    emailOrgReport(resource) {
        return axios.post(API_URL + 'email-report/' + resource.id, resource, { headers: authHeader() })
    }

}

export default new ReportService()