<template>
  <div>
    <slot v-if="!hasError">
      <slot></slot>
    </slot>
    <div v-else>
      <h2>Something went wrong.</h2>
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasError: false,
      error: null,
    };
  },
  errorCaptured(error, vm, info) {
    this.hasError = true;
    this.error = error;
    console.error('Error captured:', error, vm, info);
    return false;
  },
};
</script>

<style scoped>
</style>
