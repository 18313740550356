<template>
  <div>
   <QRCodeVue3
          :width="200"
          :height="200"
          :value="employeeCode"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
          :dotsOptions="{
            type: 'dots',
            color: '#26249a',
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                { offset: 0, color: '#322105' },
                { offset: 1, color: '#322105' },
              ],
            },
          }"
          :backgroundOptions="{ color: '#ffffff' }"
          :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
          :cornersDotOptions="{ type: undefined, color: '#000000' }"
          fileExt="png"
          :download="true"
          myclass="my-qur"
          imgclass="img-qr"
          downloadButton="qr-code-btn"
          :downloadOptions="{ name: EmployeeCode, extension: 'png' }"
        />
  </div>
</template>

<script>
import QRCodeVue3 from "qrcode-vue3";

export default {
  props: {
    EmployeeCode:String,
    qrDownload:Function
  },
  name: 'QRCodeVue3Example',
  components: {
    QRCodeVue3
  },

  computed:{
    employeeCode(){
			const url = process.env.VUE_APP_BASE_URL +'tip?emp=';
			return url+this.EmployeeCode.replaceAll(" ", "%20")
		}
  },

  methods:{
    // commented out
    // downloadQr(event){
    //   if(event){
    //     event.target.downloadQr
    //   }
    // }
  },
}
</script>
<style>
  .qr-code-btn {
    position: absolute !important;
    left: 0.5em;
    font-family: "Roboto", sans-serif;
    bottom: 0.4em;
    border: 1px solid;
    padding: 0.6em;
    color: white;
    background: #322105;
    border-radius: 0.4em;
    text-transform: uppercase;
    font-size: .9em;
    font-weight: 700;
  }
</style>