<template>
	<v-row no-gutter class="ma-1">
		<v-col cols="12" md="12" class="d-flex justify-center">
			<v-card width="820" variant="flat" rounded="sm" class="pa-1">
				<template v-slot:title>
					<div class="text-secondary font-weight-bold">Terms & Conditions</div>
				</template>
				<template v-slot:text>
					<div>
						<p class="text-caption text-justify">{{ tandc.intro }}</p>
					</div>
					<div class="mt-3">
						<v-alert 
							v-for="(term, index) in tandc.terms" 
							:key="index" 
							icon="mdi-square-small"
							variant=""
							class="my-n4"
						>	
							<span class="text-secondary font-weight-bold">{{ term.title + ':' }}</span><span class="text-caption text-justify">{{ ' ' + term.descritpion }}</span>
						</v-alert>
					</div>
				</template>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	data: () => ({
		tandc: {
			intro: `These General Terms and Conditions ("Terms and Conditions") govern the use of the Gratify
					smartphone application, which can also be used as a web app at https://appsolve.biz, and its
					services ("App").
					By downloading and using the App, the user fully accepts the content of these General Terms and
					Conditions, agreeing also with Ms-pesa terms and conditions. Therefore, the user is
					invited to read these General Terms and Conditions carefully before downloading and using the App.
					The App and the site https://appsolve.biz  ("Site") is owned by Gratify Ltd. with
					registered office in Westlands, Nairobi, Kenya, which created, developed and
					manages them.
					For further information about the Company, the App and the services offered through the App, the
					Company invites the user to consult the above mentioned Site.`,
			terms: [
				{
					title: "Technical Requirements",
					descritpion: `Downloading the App is free of charge but in order to do so, as well as to take advantage of the
								  services offered by the App, the user must be connected to the Internet network (for example via
								  3G, 4G or WiFi).
								  
								  The App is available: on the App Store for iOS devices and on Google Play Store for Android devices.

								  The Company will communicate from time to time on its Site the evolution of the compatibility of the
								  App compared to the versions of the iOS and Android operating systems.
								  Since the Company may from time to time update or modify the App in order to comply with legal
								  and regulatory changes or in order to improve its services or offer new ones, the Company will inform
								  users, through the Site as well as through the App itself, when updates for the App will be available.
								  You acknowledge and agree that some of these updates may be mandatory if you intend to continue
								  using the App and that some older versions of the App may become obsolete or cease to be
								  supported over time.`,
				},
				{
					title: "Account creation",
					descritpion: `In order to use the App it is necessary to proceed with the registration through the creation of a
								  personal account, as the download of the App alone is not sufficient for this purpose. Without
								  prejudice to the provisions of Article 5 of these General Conditions, the creation of the account is
								  free of charge.
								  In order to create an account it is necessary to provide the Company with your personal data and
								  further information that will be processed by the Company in accordance with the regulations on the
								  processing and protection of personal data. To this end, the Company invites the user to read the
								  Privacy Policy before using the App, which can always be consulted in the "Legal Documents"
								  section of the App.processing personal data relating to users who surf, interact, register, request or use the services
								  offered on the Site and the App.`,
				},
				{
					title: "Security",
					descritpion: `Access credentials must be kept with extreme care and attention by the user. They may only be
								  used by the user and may not be transferred to third parties. The user undertakes to keep them
								  secret and to ensure that no third party has access to them.
								  You also undertake to inform the Company immediately if you suspect or become aware of any
								  misuse, fraudulent, unauthorized use and/or undue disclosure.
								  In this case, the Company reserves the right to suspend the user's account in order to carry out the
								  appropriate checks and, to this end, reserves the right to ask the user to send any information and/or
								  document to ascertain the identity of the same in the event of a subsequent request for reactivation.`,
				},
				{
					title: "App services and features",
					descritpion: `The App allows the user to create his own account by choosing between the following different types
								 of accounts: "Tipper", "Tipped" and "Team" (described below).
								 Any reference to the term "user" in these General Conditions shall be understood as referring to
								 any user using the App, whether Tipper, Tipped or Team, unless otherwise expressly provided.
								 Creating your account allows the user to:
								 manage your account and privacy settings (for example: choose to make your full name or
								 profile photo visible to other users);
								 enable and disable push notifications and notifications for messages received from other
								 users.`,
				},
				{
					title: "Special features for Tipped",
					descritpion: `select your category (e.g. employee);
								  generate a personal QR-Code containing your personal data to show to Tipper in order to
								  allow Tipper to arrange the payment of the desired tip to Tipped.`,
				},
				{
					title: "Special features for the Tipper",
					descritpion: `To arrange the payment of the desired tip in favour of a Tipped you don't need to create a Tipper
								  account as you just need to frame the Tipped QR-Code through the App. However, the creation of
								  the Tipper account is suggested in order to have all the features and services offered by the App.`,
				},
				{
					title: "Special Features for Team",
					descritpion: `select the category of your facility (for example: establishment) and record the
									tips data related to it in the App.
									Generate a QR-Code containing the data of your employee to show to Tipper in order to allow
									Tipper to arrange the payment of the desired tip.`,
				},
				{
					title: "Payments",
					descritpion: `The payments and the tipping arrangement in favour of Tipped or the Team can only
								  be made via m-pesa or any other means of payment from time to time indicated as valid by the
								  Company on the Site or App.`,
				},
			],
		},
	}),
};
</script>