<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-card elevation="1" width="400" class="mx-auto">
      <v-card-title primary-title class="text-center">
        <v-img
          height="150px"
          width="150px"
          class="rounded-circle mx-auto"
          src="@/assets/gratifyLogo.jpeg"
          alt="John"
        ></v-img>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent class="ma-3">
          <v-text-field
            v-model="editedItem.phone_number"
            :readonly="loading"
            :rules="[required]"
            class="mb-2"
            clearable
            variant="outlined"
            label="Phone Number"
          ></v-text-field>
          <v-btn
            :loading="loading"
            block
            color="#FFC501"
            size="large"
            type="submit"
            variant="elevated"
            class="mt-7"
            @click="save"
          >
            <span class="text-white"> Reset </span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import AuthService from "@/services/auth.service";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      email: "",
      loading: false,
      editedItem: {
        phone_number: "",
      },
    };
  },

  methods: {
    async validate() {
      this.valid = await this.$refs.form.validate();
    },

    formatPhoneNumber(phoneNumber) {
      const pattern = /^(0|254|\+254)([1-9]\d{8})$/;
      if (pattern.test(phoneNumber)) {
        if (phoneNumber.startsWith("0")) {
          return "+254" + phoneNumber.slice(1);
        } else {
          return "+254" + phoneNumber.slice(-9);
        }
      } else if (/^[71]/.test(phoneNumber)) {
        return "+254" + phoneNumber;
      } else {
        return phoneNumber;
      }
    },

    async save() {
      this.loading = true;
      this.editedItem.phone_number = this.formatPhoneNumber(this.editedItem.phone_number);
      AuthService.forggotPasswordOtp(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.email = response.data.data;
            localStorage.setItem("password_reset_number", this.editedItem.phone_number);
            this.$store.dispatch("alert/success", response.data.message);
            this.$router.push(this.$route.query.redirect || "/verify");
            this.loading = false;
          } else {
            console.log(response, "res");
            this.$store.dispatch("alert/error", response.data.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error);
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
  },
};
</script>
