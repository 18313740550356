// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class EmployeeService {

    // index(id) {
    //     return axios.get(API_URL + 'org-employees/' + id, { headers: authHeader() })
    // }

    index(id, page, itemsPerPage) {
        return axios.get(API_URL + 'org-employees/' + id, {
            params: {
                page: page,
                itemsPerPage: itemsPerPage
            },
            headers: authHeader()
        });
    }

    show(id) {
        return axios.get(API_URL + 'org-employees/' + id, { headers: authHeader() })
    }

    store(resource) {
        return axios.post(API_URL + 'people', resource, { headers: authHeader() })
    }

    update(resource) {
        return axios.put(API_URL + 'people/' + resource.id, resource, { headers: authHeader() })
    }

    delete(resource) {
        return axios.delete(API_URL + 'people/' + resource.employee_id, { headers: authHeader() })
    }

    // oter urls
    getEmployeeTips(id) {
        return axios.get(API_URL + 'person-tips/' + id, { headers: authHeader() })
    }
}

export default new EmployeeService()
