<template>
  <v-app id="inspire">
    <v-app-bar
      elevation="1"
      density="compact"
      clipped-left
      style="position: fixed"
      class="text-white font-weight-bold"
      color="#FFC501"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <template v-slot:append>
        <div v-if="user" class="text-overline pa-1">
          <div class="mt-1 text-right font-weight-bold">
            {{
              user && user.firstname && user.lastname
                ? user.firstname + " " + user.lastname
                : ""
            }}
          </div>
          <div class="mt-n3 font-weight-bold">
            {{ userCompany ? userCompany.organisation_name : "" }}
          </div>
        </div>

        <div class="text-center">
          <v-menu open-on-hover>
            <template v-slot:activator="{ props }">
              <v-avatar v-bind="props" class="ma-1" color="white">
                <span class="text-h6 text-secondary"> {{ initials }}</span>
              </v-avatar>
            </template>

            <v-list>
              <!-- update profile -->
              <v-list-item class="mb-n2" @click="updateProfile">
                <div class="pa-1">
                  <v-icon
                    size="large"
                    color="#FFC501"
                    icon="mdi-account"
                    @click="updateProfile"
                  >
                  </v-icon>
                  Profile
                </div>
              </v-list-item>
              <v-list-item @click="handleLogout">
                <v-list-item-title>
                  <div class="pa-1">
                    <v-icon
                      size="large"
                      color="#FFC501"
                      icon="mdi-logout-variant"
                    >
                    </v-icon>
                    Logout
                  </div>
                  <!-- settings for set up -->
                  <div class="text-center">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <div class="pa-1" v-bind="props">
                          <v-icon
                            size="large"
                            color="#FFC501"
                            icon="mdi-cog"
                          ></v-icon>
                          Setup Settings
                        </div>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(item, index) in settings"
                          :key="index"
                          :to="item.route"
                        >
                          <template v-slot:title>
                            <span
                              :color="item.color"
                              class="text-subtitle-2 ml-5 pr-1"
                              >{{ item.title }}</span
                            >
                          </template>
                          <template v-slot:prepend>
                            <v-icon
                              color="secondary"
                              class="mr-n10 pr-2"
                              :icon="item.icon"
                            ></v-icon>
                          </template>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-app-bar>

    <!-- Left Drawer -->
    <v-navigation-drawer
      style="position: fixed"
      class=""
      elevation="1"
      clipped
      v-model="drawer"
    >
      <!--  -->

      <v-list class="mx-3">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.route"
          :value="item.route"
          :base-color="item.color"
          rounded="lg"
          class="my-1"
          :color="item.color"
          v-model="selectedItem"
          @click="toggleActive(item)"
        >
          <v-list-group class="mr-n10 pa-1" v-if="item.children" :value="item">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" rounded="lg" class="ml-n5 mr-n10">
                <template v-slot:prepend>
                  <v-btn size="small" icon class="ma-1">
                    <v-icon
                      size="x-large"
                      color="#FFC501"
                      :icon="item.icon"
                    ></v-icon>
                  </v-btn>
                </template>
                <template v-slot:title>
                  <span class="text-subtitle-2 ml-4">{{ item.title }}</span>
                </template>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :value="child.title"
              :to="child.route"
              rounded="lg"
              class="ml-n9"
            >
              <template v-slot:prepend>
                <v-avatar class="mr-n10 pr-2" :icon="child.icon"></v-avatar>
              </template>
              <template v-slot:title>
                <span :color="child.color" class="text-subtitle-2 ml-5 pr-1">{{
                  child.title
                }}</span>
              </template>
            </v-list-item>
          </v-list-group>
          <template v-slot:prepend>
            <v-btn v-if="!item.children" size="small" icon class="ma-1">
              <v-icon
                size="x-large"
                color="secondary"
                :icon="item.icon"
              ></v-icon>
            </v-btn>
          </template>

          <v-list-item-title
            v-if="!item.children"
            class="text-subtitle-2 ml-4"
            v-text="item.title"
          ></v-list-item-title>
        </v-list-item>
      </v-list>

      <!-- update profile -->
      <v-dialog v-model="dialog" width="500">
        <v-form ref="form">
          <v-card title="Update Profile">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="editedItem.firstname"
                    :rules="[rules.firstname]"
                    name="firstname"
                    label="First Name"
                    readonly
                    variant="outlined"
                    class="mt-2"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="editedItem.lastname"
                    :rules="[rules.lastname]"
                    name="lastname"
                    label="Last Name"
                    readonly
                    variant="outlined"
                    class="mt-2"
                  ></v-text-field>
                </v-col>

                <v-col class="mt-n7" cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.email"
                    :rules="[rules.email]"
                    name="email"
                    label="Email"
                    readonly
                    variant="outlined"
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col class="mt-n7" cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.phone"
                    :rules="[rules.phonenumber]"
                    name="phone"
                    label="Phone Number"
                    readonly
                    variant="outlined"
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col class="mt-n6" cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.oldPassword"
                    :appendInnerIcon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    label="Current Password"
                    hint="At least 8 characters"
                    counter
                    variant="outlined"
                    @click:append-inner="show1 = !show1"
                  ></v-text-field>
                </v-col>
                <v-col class="mt-n5" cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.password"
                    :appendInnerIcon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.password]"
                    :type="show1 ? 'text' : 'password'"
                    label="New Password"
                    hint="At least 8 characters"
                    counter
                    required
                    variant="outlined"
                    @click:append-inner="show1 = !show1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn variant="elevated" color="error" @click="close">
                Cancel
              </v-btn>
              <v-btn
                color="secondary"
                variant="elevated"
                @click="profileSave"
                :loading="loading"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <!--end of update profile -->
    </v-navigation-drawer>
    <v-main class="ma-2">
      <router-view></router-view>
    </v-main>
    <v-footer
      color="secondary"
      height="34px"
      density="compact"
      style="position: fixed"
      elevation="1"
      :class="drawer ? 'mt-1 ml-2 text-white' : 'mt-1 text-white'"
      app
    >
    </v-footer>
  </v-app>
</template>
  

  <script>
import AuthService from "@/services/auth.service";
export default {
  data: () => ({
    drawer: null,
    selectedItem: null,
    dialog: false,
    show1: false,
    theUser: {},
    items: [
      {
        title: "Dashboard",
        icon: "mdi-widgets",
        route: "/dashboard",
        color: "primary",
      },
      // {
      // 	title: "Organisation Tips",
      // 	icon: "mdi-domain",
      // 	route: "/organisation-tips",
      // 	color: "primary",
      // },
      // {
      //   title: "Employees",
      //   icon: "mdi-account-group-outline",
      //   route: "/employees",
      //   color: "primary",
      // },
      {
        title: "Organisations",
        icon: "mdi-contacts",
        route: "/organisations",
        color: "primary",
      },
      {
        title: "Users",
        icon: "mdi-account-group",
        route: "/users",
        color: "primary",
      },
      // {
      // 	title: "Rating",
      // 	icon: "mdi-star",
      // 	route: "/ratings",
      // 	color: "primary",
      // },
      // {
      // 	title: "Reports",
      // 	icon: "mdi-chart-timeline-variant",
      // 	route: "/report",
      // 	color: "primary",
      // },
      {
        title: "Settings",
        icon: "mdi-cog",
        route: "",
        color: "primary",
        children: [
          {
            title: "Mpesa Cost",
            icon: "mdi-currency-usd",
            route: "/transaction-cost",
            color: "primary",
          },
          {
            title: "Comments",
            icon: "mdi-comment-text-multiple",
            route: "/comments",
            color: "primary",
          },
        ],
      },
    ],

    settings: [
      {
        title: "Org Types",
        icon: "mdi-contacts",
        route: "/organisation-types",
        color: "secondary",
      },
      {
        title: "Role Category",
        icon: "mdi-shape",
        route: "/role-category-types",
        color: "secondary",
      },
      {
        title: "Role Type",
        icon: "mdi-format-list-bulleted-type",
        route: "/role-type",
        color: "secondary",
      },
      {
        title: "Commission Cost",
        icon: "mdi-currency-usd",
        route: "/commission-cost",
        color: "secondary",
      },
      {
        title: "Reports",
        icon: "mdi-comment-text-multiple",
        route: "/reports",
        color: "secondary",
      },
    ],

    rules: {
      email: (value) => !!value || "email required",
      password: (value) => !!value || "new pasword required",
      firstname: (value) => !!value || "first name required",
      lastname: (value) => !!value || "last name required",
      phonenumber: (value) => !!value || "phone number required",
    },

    loading: false,
    editedItem: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
      oldassword: "",
    },
    defaultItem: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
      oldPassword: "",
    },
  }),

  computed: {
    loggedInUser() {
      return this.$store.state.auth.user
    },

    user() {
      return this.loggedInUser || {};
    },

    userCompany() {
      const storedCompany = localStorage.getItem("company");
      return storedCompany ? JSON.parse(storedCompany) : null;
    },

    initials() {
      if (this.user && this.user.firstname && this.user.lastname) {
        return `${this.user.firstname[0].toUpperCase()}${this.user.lastname[0].toUpperCase()}`;
      } else {
        return "";
      }
    },
  },


  created() {
    console.log('The users', this.user);
  },

  methods: {
    toggleActive(item) {
      if (item === this.selectedItem) {
        this.selectedItem = null;
      } else {
        this.selectedItem = item;
      }
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    updateProfile() {
      this.editedItem.firstname = this.user ? this.user.firstname : null;
      this.editedItem.lastname = this.user ? this.user.lastname : null;
      this.editedItem.phone =
      this.user.phoneNumbers && this.user.phoneNumbers.length > 0
          ? this.user.phoneNumbers[0].number
          : null;
      this.editedItem.email = this.user && this.user.email ? this.user.email : null;
      this.editedItem.password = "";
      this.dialog = true;
    },
    
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.loading = false;
      });
    },

    async validate() {
      this.valid = await this.$refs.form.validate();
    },

    async profileSave() {
      await this.validate();
      if (this.valid || this.valid.valid) {
        (this.loading = true),
          AuthService.resetPassword(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch(
                  "alert/success",
                  "profile updated Successfully"
                );
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", "Something Went Wrong!");
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error);
              }
              if (error.response.status == 401) {
                this.handleLogout();
              }
              this.close();
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
      }
    },
  },
};
</script>