// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

const customDarkTheme = {
	dark: true,
	colors: {
		background: "#15202b",
		surface: "#15202b",
		primary: "#322105",
		secondary: "#03dac6",
		error: "#E83731",
		info: "#2196F3",
		success: "#4caf50",
		warning: "#fb8c00",
	},
};
const customLightTheme = {
	dark: false,
	colors: {
		background: "#F5F5F5",
		// surface: "#FFC501",
		primary: "#322105",
		secondary: "#FFC501",
		error: "#E57373",
	},
};


// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
	// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
	theme: {
		defaultTheme: "customLightTheme",
		themes: {
			customDarkTheme,
			customLightTheme,
		},
	}
})
