<template>
  <v-menu v-model="isMenuOpen" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-text-field
        :label="label"
        v-bind="props"
        :model-value="formattedDate"
        readonly
        variant="outlined"
        hide-details
      ></v-text-field>
    </template>
    <v-date-picker v-model="selectedDate" hide-actions title="" :color="color">
      <template v-slot:header></template>
    </v-date-picker>
  </v-menu>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits, toRefs } from 'vue';

const props = defineProps({
  label: String,
  color: String,
  modelValue: [String, Number, Boolean],
});
const { label, color, modelValue } = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const isMenuOpen = ref(false);
const selectedDate = ref(modelValue.value); 

const formattedDate = computed(() => {
  if (!selectedDate.value) return '';
  const date = new Date(selectedDate.value);
  return isNaN(date.getTime()) ? '' : date.toLocaleDateString("en");
});

watch(modelValue, (newDate) => {
  selectedDate.value = newDate;
}, { deep: true });

// watch date picked
watch(selectedDate, (newDate) => {
  emit('update:modelValue', newDate);
  isMenuOpen.value = false;
});
</script>

<style>
.v-overlay__content:has(> .v-date-picker) {
  min-width: auto !important;
}
.v-picker-title {
  padding: 0 !important;
}
</style>
