// Data sevice
import axios from 'axios'
import authHeader from './auth-header'
const API_URL = process.env.VUE_APP_API_URL;

class UserService {

    /**
     * Users
     */
    getAllUsers() {
        return axios.get(API_URL + 'users', { headers: authHeader() })
    }

    storeUser(user) {
        return axios.post(API_URL + 'users', user, { headers: authHeader() })
    }
    
    updateUser(user) {
        return axios.put(API_URL + 'users/' + user.id, user, { headers: authHeader() })
    }

    updateImage(company) {
        return axios.post(API_URL + 'update-user-image', company, { headers: authHeader() })
    }

    getUser(user) {
        return axios.get(API_URL + 'users/' + user.employee_id, { headers: authHeader() })
    }

    deleteUser(user) {
        return axios.delete(API_URL + 'users/' + user.id, { headers: authHeader() })
    }

    verifyUser(user) {
        return axios.post(API_URL + 'verify-users/' + user.id, user, { headers: authHeader() })
    }

    getAllMonthlyUsersByRole() {
        return axios.get(API_URL + 'get-users-by-role', { headers: authHeader() })
    }

}

export default new UserService()