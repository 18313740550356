<template>
	<v-dialog v-model="snackbar" width="400">
		<v-row>
			<v-col cols="1">
				<v-btn
					variant="flat"
					color="success"
					class="close-button"
					icon
          size="x-small"
					@click="closeDialog"
				>
					<v-icon size="17" class="success">mdi-close</v-icon>
				</v-btn>
			</v-col>
      <v-spacer></v-spacer>
			<v-col cols="11">
				<v-alert type="success">
					<template v-slot:text>
						<span class="text-justify">
							Dear Customer, you have tipped
							{{
								bodyData.employeeName +
								" " +
								"KES" +
								" " +
								bodyData.amount
							}}
							at
							{{
								bodyData.employeeOrg == "ArtCaffe"
									? bodyData.employeeOrg
									: "ArtCaffe" + " " + bodyData.employeeOrg
							}}.Service fee cost is
							{{ "KES" + " " + bodyData.cost }}. Powered by
							Gratify.
						</span>
					</template>
				</v-alert>
			</v-col>
		</v-row>
	</v-dialog>
</template>


<script>
import { getMessaging, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

// Initialize Firebase with your project configuration
const firebaseConfig = {
	apiKey: "AIzaSyCUa5hfVKq47tC14g7NvpgSajUI8Bl46e4",
	authDomain: "theblueion.firebaseapp.com",
	projectId: "theblueion",
	storageBucket: "theblueion.appspot.com",
	messagingSenderId: "890399704904",
	appId: "1:890399704904:web:c00785186fb9626e61d57d",
	measurementId: "G-SNNCV9MMWS",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export default {
	name: "FcmComponent",
	data: () => ({
		snackbar: false,
		bodyData: "",
		text: "Hello, I'm a snackbar",
	}),

	created() {
		onMessage(messaging, (payload) => {
			this.bodyData = JSON.parse(payload.notification.body);
			// console.log("Message received. ", this.bodyData);
			this.text = payload.notification.title;
			this.bodyData.mpesaReceiptNumber
				? (this.snackbar = true)
				: this.$store.dispatch("alert/error", this.bodyData.message);
		});
	},
	methods: {
		closeDialog() {
			this.snackbar = false;
		},
	},
};
</script>
<style scoped>
.close-button {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
}
</style>


