import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;

class tipService {
    makePayment(resource) {
        return axios.post(API_URL + 'make-payment', resource)
    }

    getPersonByEmpId(EmpId){
        return axios.get(API_URL + 'person/' + EmpId);
    }
}

export default new tipService();