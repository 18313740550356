<template>
	<Dashboard-component />
</template>
  
<script>
import { defineComponent } from "vue";

// Components
import DashboardComponent from "@/components/dashboard/DashboardComponent.vue"

export default defineComponent({
	name: "DashboardView",

	components: {
		DashboardComponent,
	},
});
</script>
  