import AuthService from '../services/auth.service'
import UserService from '../services/user.service'
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user, selected_user: null } : { status: { loggedIn: false }, user: null, selected_user: null };
const snackbar = {
    show: false,
    message: '',
    color: ''
}

export const auth = {
    namespaced: true,
    state: {...initialState, snackbar },
    actions: {
        resetCompanylogo({ commit }, logo) {
            commit('resetCompanylogo', logo)
        },

        clearSnack({ commit }) {
            commit('clearSnack');
        },

        setSelectedUser({ commit }, item) {
            commit('setSelectedUser', item)
        },

        login({ commit }, user) {
            return AuthService.login(user).then(
                response => {
                    if (response.status == 200) {
                        commit('loginSuccess', response.data)
                        return Promise.resolve(response.data)
                    } else {
                        commit('loginFailure', response)
                        return Promise.reject(response)
                    }

                },
                error => {
                    commit('loginFailure', error)
                    return Promise.reject(error)
                }
            )
        },

        logout({ commit }) {
            AuthService.logout()
            commit('logout')
        },

        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    if (response.status == 200) {
                        commit('registerSuccess', response)
                        return Promise.resolve(response.data)
                    } else {
                        if (response.errors) {
                            this.$store.dispatch("tender/setValidationErrors", response.errors);
                        }

                        if (response.response.data.errors) {
                            this.$store.dispatch("tender/setValidationErrors", response.response.data.errors);
                        }

                        commit('validationError', response.data.error)
                        return Promise.reject(response)
                    }
                },
                error => {
                    if (error.response && error.response.errors) {
                        this.$store.dispatch("tender/setValidationErrors", error.response.errors);
                    }

                    commit('registerFailure', error)
                    return Promise.reject(error)
                }
            )
        },

        updateUser({ commit }, user) {
            return UserService.getUser(user).then(
                response => {
                    console.log(response.data)
                    commit('updateUser', response.data)
                    return Promise.resolve(response.data)
                },
                error => {
                    commit('updateFailure', error)
                    return Promise.reject(error)
                }
            )
        },


        updateUserImage({ commit }, company) {
            return UserService.updateImage(company).then(
                response => {
                    // console.log(response);
                    commit('updateProfileSuccess', response.data)
                    return Promise.resolve(response.data)
                },
                error => {
                    commit('updateFailure', error)
                    return Promise.reject(error)
                }
            )
        },
    },

    mutations: {
        loginSuccess(state, response) {
            state.status.loggedIn = true
            state.snackbar.show = true
            state.snackbar.color = 'success'
            state.snackbar.message = 'User Login Successful!'
            localStorage.setItem('user', JSON.stringify(response.data.person))
            localStorage.setItem('token', response.data.token)
            state.user = response.data
        },
        loginFailure(state, error) {
            console.log(error);
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = error.response.data.message ||
                (error.response && error.response.data) ||
                error.mesage ||
                error.toString();
        },
        emailNotVerified(state) {
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = 'Please Check your Email for a Verification Link and Verify your Email!'
        },
        logout(state) {
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = false
            state.snackbar.color = ''
            state.snackbar.message = ''
        },
        registerSuccess(state, response) {
            state.status.loggedIn = true
            state.snackbar.show = true
            state.snackbar.color = 'success'
            state.snackbar.message = 'User Registration Successful! Please Check your Email for a Verification Link!'
            state.user = response.data.user
        },
        registerFailure(state, error) {
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = error.response.data.message || error.response.data.errors
        },
        validationError(state, errors) {
            let msg = ''
            let errorsArray = Object.values(errors);
            console.log(errorsArray)
            errorsArray.forEach(element => {
                console.log(element)
                msg = msg + element + '.'
            });
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = msg
        },
        clearSnack(state) {
            state.snackbar.show = false
            state.snackbar.color = ''
            state.snackbar.message = ''
        },
        updateSuccess(state, response) {
            console.log(response.data)
            state.user = response.data
        },
        updateProfileSuccess(state, response) {
            state.user = response.data
        },
        updateUser(state, response) {
            state.user = response.data
        },
        updateFailure(state, error) {
            console.log(error);
            // state.user.company = null
        },
        resetCompanylogo(state, data) {
            state.user.company.company_logo = data
        },
        setSelectedUser(state, item) {
            state.selected_user = item
        },

    },
    getters: {
        user: (state) => state.user
    }

}