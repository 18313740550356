const initialState = {
    fcm_notification:{},
    device_token:{}
}

export const notification = {
    namespaced: true,
    state: initialState,
    actions: {
        setFcmNotification({ commit }, item) {
            commit('setFcmNotification', item)
        },

        setDeviceToken({ commit }, item) {
            commit('setDeviceToken', item)
        },

    },

    mutations: {
        setFcmNotification(state, item) {
            state.fcm_notification = item
        },

        setDeviceToken(state, item) {
            state.device_token = item
        },
    },
    
}