<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-card elevation="1" width="400" class="mx-auto">
      <v-card-title primary-title class="text-center">
        <v-img
          height="150px"
          width="150px"
          class="rounded-circle mx-auto"
          src="@/assets/gratifyLogo.jpeg"
          alt="John"
        ></v-img>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation ref="form" class="ma-3">
          <v-text-field
            v-model="user.email"
            :rules="[rules.required]"
            class="mb-2"
            clearable
            variant="outlined"
            label="Email"
          ></v-text-field>
          <v-text-field
            v-model="user.password"
            :appendInnerIcon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            label="Password"
            hint="At least 8 characters"
            counter
            variant="outlined"
            @click:append-inner="show1 = !show1"
          ></v-text-field>
          <v-btn
            @click="forgottenPassword"
            variant="text"
            class="ml-n2 mb-n8"
            size="x-small"
            >Forgotten Password?</v-btn
          >
          <v-btn
            :loading="loading"
            block
            color="#FFC501"
            size="large"
            variant="elevated"
            class="mt-7"
            @click="handleLogin"
          >
            <span class="text-white"> Sign In </span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import User from "../../models/user";
import AuthService from "@/services/auth.service";

import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      user: new User("", "", ""),
      valid: false,
      remember: 0,
      resultArray: Boolean,
      loading: false,
      person: [],
      timeout: 2000,
      show1: false,
      show2: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  components: {},
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    computedLogo() {
      return this.config.logo_url;
    },
    validation() {
      return this.$store.state.tender.validationErrors;
    },
  },

  created() {
    localStorage.clear();
  },

  methods: {
    async validate() {
      this.valid = await this.$refs.form.validate();
    },

    getValidationErrors() {
      axios.interceptors.response.use(
        (res) => {
          this.$store.dispatch("alert/error", []);
          return res;
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.errors);
          }

          return error;
        }
      );
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    goToRegister() {
      this.$router.push("/register");
    },
    gotToForgotPassword() {
      this.$router.push("/forgot-password");
    },

    forgottenPassword() {
      this.$router.push(this.$route.query.redirect || "/forgotten-password");
    },

    async getPerson(id) {
      try {
        const response = await AuthService.getPerson(id);
        if (response.status === 200) {
          this.person = response.data.data;
          // const employerOrganisation =
          //   this.person?.person?.party?.party_roles[0]
          //     ?.party_relation_ship_type_to[0]?.party_relation_ships[0]
          //     ?.employer?.party?.organisation;
          const employerOrganisation = null;
          if (employerOrganisation) {
            localStorage.setItem(
              "company",
              JSON.stringify(employerOrganisation)
            );
          } else {
            localStorage.setItem("company", JSON.stringify({ id_parent: 1 }));
          }
        } else {
          this.person = [];
          this.$store.dispatch(
            "alert/error",
            "An error occurred while fetching person data."
          );
          console.error(
            "Error fetching person data:",
            response.data.console.error
          );
        }
      } catch (error) {
        this.person = [];
        if (error.response?.status === 401) {
          this.handleLogout();
        } else {
          console.error(error);
          this.$store.dispatch(
            "alert/error",
            error.message || "An unknown error occurred."
          );
        }
      } finally {
        this.loading = false;
      }
    },

    async handleLogin() {
      await this.validate();
      if (this.valid.valid) {
        this.loading = true;
        try {
          const response = await this.$store.dispatch("auth/login", this.user);

          if (response.statusCode === 200 && response.data) {
            await this.getPerson(response.data.id);

            this.loading = false;
            this.$store.commit("login");
            this.$store.dispatch("startInactivityTimeout");
            const roleCategoryArray = response.data.roleCategory;
            this.resultArray = roleCategoryArray.map((roleCategory) => {
              // const includesEstablishment = roleCategory.role_category
              //   .toLowerCase()
              //   .includes('establishment', 'administrator');
              const includesEstablishment =
                roleCategory.role_category.toLowerCase() == "administrator" ||
                roleCategory.role_category.toLowerCase() == "establishment";
              localStorage.setItem(
                "role_category",
                roleCategory.role_category.toLowerCase()
              );

              return includesEstablishment;
            });

            if (
              response.data.roleCategory &&
              this.resultArray.every((value) => value)
            ) {
              this.$router.push(this.$route.query.redirect || "/dashboard");
            } else {
              this.$store.dispatch("alert/error", "Not authorised to login");
            }
          }
        } catch (error) {
          console.log(error, "errrrr");
          this.loading = false;
          this.$store.dispatch(
            "alert/error",
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>
