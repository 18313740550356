<template>
  <div class="ma-1">
    <!-- Admin Report -->
    <div class="admin" v-if="is_admin">
      <v-card outlined>
        <v-row class="mx-1 py-2">
          <v-col cols="4" class="mt-2">
            <span
              class="my-auto text-h6 text-uppercase font-weight-bold text--darken-1 justify-center"
              >Organisation Report</span
            >
          </v-col>

          <v-col cols="8">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  dark
                  size="large"
                  class="float-right mr-n2"
                  :loading="downloadLoading"
                  prepend-icon="mdi-download"
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadReportPdf"
                  variant="elevated"
                >
                  Download
                </v-btn>
              </template>
              <span>Download Report</span>
            </v-tooltip>

            <!-- have filter here -->
            <!-- start filter -->
            <div class="text-center">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                location="end"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    color="secondary"
                    dark
                    size="large"
                    prepend-icon="mdi-magnify"
                    class="float-right mr-2"
                  >
                    Filter by Date
                  </v-btn>
                </template>

                <v-card min-width="300">
                  <!-- form -->
                  <v-form>
                    <v-col cols="12">
                      <date-picker
                        label="From Date"
                        v-model="from"
                        color="secondary"
                      ></date-picker>
                    </v-col>
                    <v-col cols="12">
                      <date-picker
                        label="To Date"
                        v-model="to"
                        color="secondary"
                      ></date-picker>
                    </v-col>
                  </v-form>
                  <v-card-actions class="mt-2">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      variant="elevated"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="secondary"
                      variant="elevated"
                      :loading="loading"
                      @click="getorgReport"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
            <!-- end filter -->
            <!--  -->
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- report headers -->
        <div class="mt-n2">
          <div
            class="m-auto my-3 pt-10 text-center align-center"
            max-width="100%"
          >
            <div class="mb-3">
              <h2 class="error--text text--darken-1 text-uppercase">
                {{
                  orgReport.organisation_name
                    ? orgReport.organisation_name + " " + "REPORT"
                    : "REPORT"
                }}
              </h2>
              <div class="text-primary text-caption text--darken-1">
                <div>
                  <span class="font-weight-black"> Withdrawals: </span>
                  {{
                    orgReport.totalWithdrawals
                      ? "KES" + " " + orgReport.totalWithdrawals
                      : "KES" + " " + "0.00"
                  }}
                </div>
                <div>
                  <span class="font-weight-black"> Balance: </span>
                  {{
                    orgReport.balance
                      ? "KES" + " " + orgReport.balance
                      : "KES" + " " + "0.00"
                  }}
                </div>
                <div>
                  <span class="font-weight-black">Tips: </span>
                  {{
                    orgReport.totalTips
                      ? "KES" + " " + orgReport.totalTips
                      : "KES" + " " + "0.00"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of header -->
        <v-tabs v-model="tab" class="my-1" bg-color="secondary">
          <v-tab value="one">Tips</v-tab>
          <v-tab value="two">Withdrawals</v-tab>
        </v-tabs>

        <!-- data table -->
        <v-window v-model="tab">
          <v-window-item value="one">
            <div>
              <v-card outlined class="ma-2">
                <v-data-table
                  :headers="headers"
                  :loading="loading"
                  :items="orgReport.tips"
                  item-key="id"
                  :items-per-page="100"
                  item-value="name"
                >
                  <template
                    v-slot:[`item.mpesa_results.transaction_date`]="{ item }"
                  >
                    {{ formatDate(item.mpesa_results.transaction_date) }}
                  </template>
                  <template v-slot:[`item.mpesa_results.amount`]="{ item }">
                    {{ formatAmount(item.mpesa_results.amount) }}
                  </template>

                  <template v-slot:[`body.append`]>
                    <tr>
                      <th class="ml-3">Total Tips:</th>
                      <v-spacer></v-spacer>
                      <th></th>
                      <th></th>
                      <th>{{ orgReport.totalTips }}</th>
                    </tr>
                  </template>
                  <template #bottom></template>
                </v-data-table>
              </v-card>
            </div>
          </v-window-item>
          <v-window-item value="two">
            <div>
              <v-card outlined class="ma-2">
                <v-data-table
                  :headers="withdrawHeaders"
                  :items="orgReport.withdrawals"
                  :loading="loading"
                  item-key="id"
                  :items-per-page="100"
                  item-value="name"
                >
                  <template
                    v-slot:[`item.TransactionCompletedDateTime`]="{ item }"
                  >
                    {{ formatDate(item.TransactionCompletedDateTime) }}
                  </template>
                  <template v-slot:[`item.TransactionAmount`]="{ item }">
                    {{ formatAmount(item.TransactionAmount) }}
                  </template>

                  <template v-slot:[`body.append`]>
                    <tr>
                      <th class="ml-3">Total Withdrawals:</th>
                      <v-spacer></v-spacer>
                      <th></th>
                      <th></th>
                      <th>{{ orgReport.totalWithdrawals }}</th>
                    </tr>
                  </template>
                  <template #bottom></template>
                </v-data-table>
              </v-card>
            </div>
          </v-window-item>
        </v-window>
        <!-- actions -->
        <v-card-actions class="mt-2">
          <v-spacer></v-spacer>
          <v-btn color="error" variant="elevated" @click="closingDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <!-- Admin Report -->
    <div class="user" v-else>
      <v-card outlined>
        <v-row class="mx-1 py-2">
          <v-col cols="4" class="mt-2">
            <span
              class="my-auto text-h6 text-uppercase font-weight-bold text--darken-1 justify-center"
              >Organisation Report</span
            >
          </v-col>

          <v-col cols="8">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  dark
                  size="large"
                  class="float-right mr-n2"
                  :loading="downloadLoading"
                  prepend-icon="mdi-download"
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadReportPdf"
                  variant="elevated"
                >
                  Download
                </v-btn>
              </template>
              <span>Download Report</span>
            </v-tooltip>

            <!-- have filter here -->
            <!-- start filter -->
            <div class="text-center">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                location="end"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    color="secondary"
                    dark
                    size="large"
                    prepend-icon="mdi-magnify"
                    class="float-right mr-2"
                  >
                    Filter by Date
                  </v-btn>
                </template>

                <v-card min-width="300">
                  <!-- form -->
                  <v-form>
                    <v-col cols="12">
                      <date-picker
                        label="From Date"
                        v-model="from"
                        color="secondary"
                      ></date-picker>
                    </v-col>
                    <v-col cols="12">
                      <date-picker
                        label="To Date"
                        v-model="to"
                        color="secondary"
                      ></date-picker>
                    </v-col>
                  </v-form>
                  <v-card-actions class="mt-2">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      variant="elevated"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="secondary"
                      variant="elevated"
                      :loading="loading"
                      @click="getorgReport"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
            <!-- end filter -->
            <!--  -->
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- report headers -->
        <div class="mt-n2">
          <div
            class="m-auto my-3 pt-10 text-center align-center"
            max-width="100%"
          >
            <div class="mb-3">
              <h2 class="error--text text--darken-1 text-uppercase">
                {{
                  orgReport.organisation_name
                    ? orgReport.organisation_name + " " + "REPORT"
                    : "REPORT"
                }}
              </h2>
              <div class="text-primary text-caption text--darken-1">
                <div>
                  <span class="font-weight-black">
                    No of employees with ratings:
                  </span>
                  {{ orgReport.totalEmployeesRated }}
                </div>
                <div>
                  <span class="font-weight-black">
                    Organization average ratings:
                  </span>
                  <v-rating
                          hover
                          readonly
                          :length="5"
                          :size="17"
                          class="mb-n4 ml-2"
                          :model-value="orgReport.employeeAvgRatings"
                          half-increments
                          variant="compact"
                          active-color="amber"
                        />
                </div>
                <!-- <div>
                <span class="font-weight-black">Tips: </span>
                {{
                  orgReport.totalTips
                    ? "KES" + " " + orgReport.totalTips
                    : "KES" + " " + "0.00"
                }}
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- end of header -->
        <v-tabs v-model="tab" class="my-1" bg-color="secondary">
          <v-tab value="one">Employee Ratings</v-tab>
          <!-- <v-tab value="two">Withdrawals</v-tab> -->
        </v-tabs>

        <!-- data table -->
        <v-window v-model="tab">
          <v-window-item value="one">
            <div>
              <v-card outlined class="ma-2">
                <v-data-table
                  :headers="headers2"
                  :loading="loading"
                  :items="orgReport.tips"
                  item-key="id"
                  :items-per-page="100"
                  item-value="name"
                >
                  <template
                    v-slot:[`item.created_at`]="{ item }"
                  >
                    {{ formatDate(item.created_at) }}
                  </template>
                  <template v-slot:[`item.rating`]="{ item }">
                    <div class="text-error" v-if="item.rating == 0">
                      {{ "No Rating" }}
                    </div>
                    <div @click="showRating(item)" v-else>
                      <v-tooltip
                        text="click to view ratings"
                        activator="parent"
                        location="bottom"
                      >
                        <template v-slot:activator="{ tooltip }">
                          <v-rating
                            hover
                            v-bind="tooltip"
                            readonly
                            :length="5"
                            :size="21"
                            :model-value="item.rating"
                            active-color="amber"
                          />
                        </template>
                      </v-tooltip>
                    </div>
                  </template>

                  <!-- <template v-slot:[`body.append`]>
                  <tr>
                    <th class="ml-3">Total Tips:</th>
                    <v-spacer></v-spacer>
                    <th></th>
                    <th></th>
                    <th>{{ orgReport.totalTips }}</th>
                  </tr>
                </template> -->
                  <template #bottom></template>
                </v-data-table>
              </v-card>
            </div>
          </v-window-item>
          <!-- <v-window-item value="two">
          <div>
            <v-card outlined class="ma-2">
              <v-data-table
                :headers="withdrawHeaders"
                :items="orgReport.withdrawals"
                :loading="loading"
                item-key="id"
                :items-per-page="100"
                item-value="name"
              >
                <template
                  v-slot:[`item.TransactionCompletedDateTime`]="{ item }"
                >
                  {{ formatDate(item.TransactionCompletedDateTime) }}
                </template>
                <template v-slot:[`item.TransactionAmount`]="{ item }">
                  {{ formatAmount(item.TransactionAmount) }}
                </template>

                <template v-slot:[`body.append`]>
                  <tr>
                    <th class="ml-3">Total Withdrawals:</th>
                    <v-spacer></v-spacer>
                    <th></th>
                    <th></th>
                    <th>{{ orgReport.totalWithdrawals }}</th>
                  </tr>
                </template>
                <template #bottom></template>
              </v-data-table>
            </v-card>
          </div>
        </v-window-item> -->
        </v-window>
        <!-- actions -->
        <v-card-actions class="mt-2">
          <v-spacer></v-spacer>
          <v-btn color="error" variant="elevated" @click="closingDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportService from "@/services/reports_service";
import moment from "moment";
import datePicker from "@/components/DatePicker";
export default {
  name: "FcmComponent",
  components: {
    datePicker,
  },
  props: {
    orgId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    is_admin: false,
    orgReport: {},
    tab: null,
    loading: false,
    downloadLoading: false,
    closeDialog: true,
    menu: false,
    to: new Date(),
    from: (() => {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date;
    })(),

    editedItem: {
      id: "",
      from: "",
      to: "",
    },
    groupBy: [
      {
        key: "gluten",
        order: "asc",
      },
    ],
    headers: [
      {
        title: "#",
        align: "start",
        sortable: true,
        key: "id",
      },
      {
        title: "Mpesa Receipt Number",
        sortable: false,
        key: "mpesa_results.mpesa_receipt_number",
      },
      {
        title: "Phone Number",
        sortable: false,
        key: "mpesa_results.phone_number",
      },
      {
        title: "Transaction Date",
        sortable: false,
        key: "mpesa_results.transaction_date",
      },
      {
        title: "Amount(KES)",
        sortable: false,
        align: "start",
        key: "mpesa_results.amount",
      },
    ],
    headers2: [
      {
        title: "#",
        align: "start",
        sortable: true,
        key: "id",
      },
      {
        title: "Rating",
        sortable: false,
        key: "rating",
      },
      {
        title: "Comment",
        sortable: false,
        key: "comment",
      },
      {
        title: "Date",
        sortable: false,
        key: "created_at",
      },
      // {
      //   title: "Amount(KES)",
      //   sortable: false,
      //   align: "start",
      //   key: "mpesa_results.amount",
      // },
    ],

    withdrawHeaders: [
      {
        title: "#",
        align: "start",
        sortable: true,
        key: "id",
      },
      {
        title: "Mpesa Receipt Number",
        sortable: false,
        key: "TransactionReceipt",
      },
      {
        title: "Reciever Name",
        sortable: false,
        key: "ReceiverPartyPublicName",
      },
      {
        title: "Transaction Date",
        sortable: false,
        key: "TransactionCompletedDateTime",
      },
      {
        title: "Amount(KES)",
        sortable: false,
        align: "start",
        key: "TransactionAmount",
      },
    ],
  }),

  watch: {
    orgId: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log("item", newVal);
        if (newVal !== oldVal) {
          this.getorgReport();
        }
      },
    },
  },

  created() {
    this.checkRole();
  },

  methods: {
    checkRole() {
      localStorage.getItem("role_category") == 'administrator'
        ? (this.is_admin = true)
        : (this.is_admin = false);
    },

    getorgReport() {
      this.loading = true;
      this.editedItem.id = this.orgId;
      this.editedItem.to = this.to;
      this.editedItem.from = this.from;
      // console.log('this.editedIte', this.editedItem)
      return ReportService.generateOrgReport(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.orgReport = response.data.data;
            console.log(this.orgReport);
            this.loading = false;
            this.menu = false;
          } else {
            this.orgReport = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.orgReport = [];
          this.loading = false;
          if (error.response.status == 401) {
            this.handleLogout();
          } else {
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        }
      );
    },

    closingDialog() {
      this.$emit("close-dialog");
    },

    formatAmount(amount) {
      if (amount === null || amount === "") {
        amount = 0;
      }
      // instantiate number
      const numericAmount = Number(amount);
      if (!isNaN(numericAmount) && isFinite(numericAmount)) {
        return numericAmount.toFixed(2);
      } else {
        console.error(
          "Invalid input: The provided value cannot be converted to a numeric format."
        );
        return "0.00";
      }
    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    printReport() {
      this.loading = true;
      this.editedItem.id = this.orgId;
      this.editedItem.to = this.to;
      this.editedItem.from = this.from;
      return ReportService.downloadOrgReport(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    downloadReportPdf() {
      this.editedItem.id = this.orgId;
      this.editedItem.to = this.to;
      this.editedItem.from = this.from;
      this.downloadLoading = true;

      ReportService.downloadOrgReport(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "PDF Generated Successfully");
            this.downloadLoading = false;
            let blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              this.orgReport.organisation_name +
              "_" +
              this.editedItem.from +
              "_" +
              this.editedItem.to +
              ".pdf";
            link.click();
          } else {
            this.downloadLoading = false;
            this.$store.dispatch(
              "alert/error",
              "Invalid data, please check the form try again!"
            );
          }
        },
        (error) => {
          this.downloadLoading = false;
          console.log(error);
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },
  },
};
</script>