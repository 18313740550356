<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-progress-circular color="secondary" :indeterminate="loading" :size="40"></v-progress-circular>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
  }),

  beforeRouteEnter(to, from, next) {
    setTimeout(() => {
      const isAuthenticated = localStorage.getItem('token');
      // console.log('token stafffs',isAuthenticated);  
      if (isAuthenticated) {
        next(vm => {
          vm.$router.push('/dashboard');
          vm.loading = false;
        });
      } else {
        next(vm => {
          vm.$router.push('/login');
          vm.loading = false;
        });
      }
    }, 1000);
  },
};
</script>
