// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class OrganisationService {

    index() {
        return axios.get(API_URL + 'organisations?parent=0', { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'organisations/' + id, { headers: authHeader() })
    }
    store(resource) {
        return axios.post(API_URL + 'organisations', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(API_URL + 'organisations/' + resource.id, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(API_URL + 'organisations/' + resource.id, { headers: authHeader() })
    }

    indexAll() {
        return axios.get(API_URL + 'organisations?parent=1', { headers: authHeader() })
    }

    // Other Routes
    orgEmployees(id) {
        return axios.get(API_URL + 'org-employees/' + id, { headers: authHeader() })
    }

    orgWallet(){
        return axios.get(API_URL + 'org-wallet', { headers: authHeader() })
    }
    orgCommission(){
        return axios.get(API_URL + 'org-commission', { headers: authHeader() })
    }

    getSortedOrTips(id){
        return axios.get(API_URL + 'sorted-organisation-tips/' + id, { headers: authHeader() })
    }

    orgTipsCount(){
        return axios.get(API_URL + 'tips-counts', { headers: authHeader() })
    }

    orgRatingsCount(){
        return axios.get(API_URL + 'ratings-counts', { headers: authHeader() })
    }

    getOrgTips(id) {
        return axios.get(API_URL + 'organisation-tips/' + id, { headers: authHeader() })
    }
    
    getAllOrgTips(id) {
        return axios.get(API_URL + 'org-tips/' + id, { headers: authHeader() })
    }

    getOrgRating(id) {
        return axios.get(API_URL + 'organisation-rating/' + id, { headers: authHeader() })
    }
    
}

export default new OrganisationService()
