<template>
	<v-snackbar
		v-model="alert.show"
		:timeout="2000"
		:color="alert.color"
		location="top right"
		class="mt-15"
	>
	<v-icon :icon="alert.icon" size="x-large" class="mr-1"></v-icon>
		{{ alert.message }}
	</v-snackbar>
</template>

<script>

export default {
	name: "AlertComponent",
	computed: {
		alert() {
			return this.$store.state.alert;
		},
	},

	watch: {
		alert: {
			handler(newAlert) {
				if (newAlert.show) {
					this.showAlert();
				}
			},
			deep: true,
		},
	},
	methods: {
		hideAlert() {
			this.$store.dispatch("alert/clear");
		},
		showAlert() {
			setTimeout(() => {
				this.$store.dispatch("alert/clear");
			}, 3000);
		},
	},
};
</script>
<style scoped>
.top-right-snackbar {
	position: fixed !important;
	top: 0% !important;
	right: 20px !important;
}
</style>
