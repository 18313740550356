<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-card elevation="1" width="400" class="mx-auto">
      <v-card-title primary-title class="text-center">
        <v-img
          height="150px"
          width="150px"
          class="rounded-circle mx-auto"
          src="@/assets/gratifyLogo.jpeg"
          alt="John"
        ></v-img>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent class="ma-3">
          <v-text-field
            v-model="editedItem.password"
            :appendInnerIcon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 8 characters"
            counter
            variant="outlined"
            @click:append-inner="show1 = !show1"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.veryfy_password"
            :appendInnerIcon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-1"
            label="Confirm Password"
            hint="At least 8 characters"
            counter
            variant="outlined"
            class="mt-2"
            @click:append-inner="show2 = !show2"
          ></v-text-field>

          <v-btn
            :loading="loading"
            block
            color="#FFC501"
            size="large"
            type="submit"
            variant="elevated"
            class="mt-7"
			@click="save"
          >
            <span class="text-white"> Reset </span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import AuthService from "@/services/auth.service";

export default {
  data() {
    return {
      show1: false,
      show2: false,
      password: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      loading: false,
      editedItem: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    passwordResetNumber() {
      return localStorage.getItem("password_reset_number");
    },
  },

  methods: {
    async validate() {
      this.valid = await this.$refs.form.validate();
    },

    async save() {
      this.loading = true;
      this.editedItem.phone = this.passwordResetNumber;
      this.editedItem.otp =  localStorage.getItem("otp");
      if ( this.editedItem.phone ) {
        AuthService.resetPassword(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.password = response.data;
            this.$store.dispatch("alert/success", response.data.message);
            this.$router.push(this.$route.query.redirect || "/login");
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error);
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
      } else {
        this.$store.dispatch("alert/error", "phone number require");
        this.$router.push(this.$route.query.redirect || "/forgotten-password");
      }

    },
  },
};
</script>
