import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

loadFonts();

const firebaseConfig = {
  apiKey: "AIzaSyCUa5hfVKq47tC14g7NvpgSajUI8Bl46e4",
  authDomain: "theblueion.firebaseapp.com",
  projectId: "theblueion",
  storageBucket: "theblueion.appspot.com",
  messagingSenderId: "890399704904",
  appId: "1:890399704904:web:c00785186fb9626e61d57d",
  measurementId: "G-SNNCV9MMWS"
};

const firebaseApp = initializeApp(firebaseConfig);
getMessaging(firebaseApp);

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .provide('firebaseApp', firebaseApp)
  .mount('#app');
